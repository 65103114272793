import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { target: String }

  declare targetValue: string;
  declare hasTargetValue: boolean;

  connect() {
    if (this.hasTargetValue) {
      document.getElementById(this.targetValue).click();
    }
  }
}
