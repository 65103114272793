import {
  IServerSideGetRowsRequest,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  SetFilterValuesFuncParams,
} from "ag-grid-enterprise";
import { convertFilterModelToServerSideFilterModel } from "./set_filters";
import { post, get } from "@rails/request.js";
import { IServerSideData } from "../../types/register";

export const createServerSideDatasource = (
  datasourceUrl: string,
  setLoadingState: (isLoading: boolean) => void,
  getCashFlowFilter: () => boolean,
) => {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      const newRequest: IServerSideGetRowsRequest & { filterForCashFlow: boolean } = {
        ...params.request,
        filterForCashFlow: getCashFlowFilter(),
      };

      // When using a set filter, the grid uses null for "empty" values. Rails removes these values on the server
      // side, so we need to swap out the null with the custom value "__empty__"
      newRequest.filterModel = convertFilterModelToServerSideFilterModel(newRequest.filterModel);

      // For tags and tag groups, the grid sends an array of group keys, we need to flatten this to a single array.
      // If there is more than one group key in the original array, we should raise an error.
      newRequest.groupKeys.forEach((groupKey) => {
        if (Array.isArray(groupKey)) {
          if (groupKey.length > 1) {
            throw new Error(`Expected a single group key, got multiple: ${groupKey}`);
          }
        }
      });

      newRequest.groupKeys = newRequest.groupKeys.flat();

      setLoadingState(true);

      post(datasourceUrl, {
        body: { detailed_ledger_entry: newRequest },
        responseKind: "json",
      })
        .then((httpResponse) => httpResponse.json)
        .then((response: IServerSideData) => {
          params.success({
            rowData: response.rows,
            rowCount: response.lastRow,
          });
        })
        .finally(() => {
          setLoadingState(false);
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  } as IServerSideDatasource;
};

export const getFilterOptions = (url: string, params: SetFilterValuesFuncParams) => {
  const queryParams = new URLSearchParams();
  const filterColumn = params.colDef.field;

  queryParams.append("filter", filterColumn);

  get(`${url}?${queryParams.toString()}`, { responseKind: "json" })
    .then((response) => response.json)
    .then((data) => {
      params.success(data.filter[filterColumn]);
    })
    .catch((error) => {
      console.error(error);
    });
};
