import { Controller } from "@hotwired/stimulus";
import { TurboFrameElement } from "@hotwired/turbo";

// Connects to data-controller="frame-loader"
export default class extends Controller<TurboFrameElement> {
  static values = {
    path: String,
  };

  static targets = ["spinner"];

  declare pathValue: string;
  declare spinnerTarget: HTMLElement;
  declare hasSpinnerTarget: boolean;

  connect() {}

  loadFrame(event: Event) {
    if (event.target instanceof HTMLElement) {
      event.target.classList.add("hidden");
    }

    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove("hidden");
    }

    this.element.loaded = "lazy";
    this.element.src = this.pathValue;
  }

  reloadFrame() {
    this.element.reload();
  }
}
