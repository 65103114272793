import { FilterModel } from "ag-grid-enterprise";

const EMPTY_VALUE_FOR_SET_FILTER = "__empty__";

function convertFilterModel(
  filterModel: FilterModel | null,
  from: null | string,
  to: null | string,
): FilterModel | null {
  if (!filterModel) {
    return null;
  }

  return Object.keys(filterModel).reduce((acc, key) => {
    acc[key] = filterModel[key];

    if (filterModel[key]?.filterType === "set") {
      const values = filterModel[key].values;

      if (values.includes(from)) {
        values[values.indexOf(from)] = to;
      }

      filterModel[key].values = values;
    }

    return acc;
  }, {});
}

// When using a set filter on AG Grid, the grid uses null for "empty" values. Rails removes these values when POSTing to
// the server, so we need to swap out the null with the custom value "__empty__".
export function convertFilterModelToServerSideFilterModel(filterModel: FilterModel | null): FilterModel | null {
  return convertFilterModel(filterModel, null, EMPTY_VALUE_FOR_SET_FILTER);
}

export function convertServerSideFilterModelToFilterModel(filterModel: FilterModel | null): FilterModel | null {
  return convertFilterModel(filterModel, EMPTY_VALUE_FOR_SET_FILTER, null);
}
