import { Controller } from "@hotwired/stimulus"

type AccountType = "Revenue" | "Expense" | string

export default class extends Controller {
  static targets = ["grossOption", "sourceOption", "useOption"]

  declare grossOptionTarget: HTMLInputElement;
  declare sourceOptionTarget: HTMLInputElement;
  declare useOptionTarget: HTMLInputElement;

  declare hasGrossOptionTarget: boolean;
  declare hasSourceOptionTarget: boolean;
  declare hasUseOptionTarget: boolean;

  connect(): void {
    const accountTypeSelect = document.getElementById('account_template_account_type') as HTMLSelectElement;
    if (accountTypeSelect) {
      this.setDefaultForAccountType(accountTypeSelect.value);
    }
  }

  handleAccountTypeChange(event: CustomEvent): void {
    const accountType = event.detail.type;
    this.setDefaultForAccountType(accountType);
  }

  private setDefaultForAccountType(accountType: AccountType): void {
    if (!this.hasGrossOptionTarget || !this.hasSourceOptionTarget || !this.hasUseOptionTarget) {
      return;
    }

    this.grossOptionTarget.checked = false;
    this.sourceOptionTarget.checked = false;
    this.useOptionTarget.checked = false;

    switch(accountType) {
      case 'Revenue':
        this.sourceOptionTarget.checked = true;
        break;
      case 'Expense':
        this.useOptionTarget.checked = true;
        break;
      default:
        this.grossOptionTarget.checked = true;
    }
  }
}
