import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["gridSettingsInput", "periodInput"];

  declare gridSettingsInputTarget: HTMLInputElement;
  declare hasGridSettingsInputTarget: boolean;
  declare periodInputTarget: HTMLInputElement;
  declare hasPeriodInputTarget: boolean;

  connect() {
    this.dispatch("connected");
  }

  updateGridState(event) {
    if (this.hasGridSettingsInputTarget) {
      this.gridSettingsInputTarget.value = JSON.stringify(event.detail.gridState);
    }

    if (this.hasPeriodInputTarget) {
      this.periodInputTarget.value = event.detail.period;
    }
  }
}
