import * as math from "mathjs";

interface CurrencyFormatterOptions {
  throwOnInvalid?: boolean;
}

export function formatCurrencyValue(inputValue: string, options: CurrencyFormatterOptions = {}): string {
  // Remove all non-numerical characters except for math operators (+, -, *, /) and decimal point
  const patterns = {
    commas: ",",
    leadingZeros: "^0+(?=\\d)", // zeros at start only if followed by digit
    nonMathChars: "[^0-9.+-/*]", // anything that's not a number or math operator
  };

  const cleanupPattern = new RegExp(`${patterns.commas}|${patterns.leadingZeros}|${patterns.nonMathChars}`, "g");
  const numericValue = inputValue.replace(cleanupPattern, "");

  let result = "";
  try {
    result = math.evaluate(numericValue);
  } catch {
    if (options.throwOnInvalid) {
      throw new Error("Invalid numeric expression. Could not parse: " + inputValue + " as " + numericValue);
    }
  }

  let numberValue = parseFloat(result);
  if (isNaN(numberValue) || numberValue === Infinity) {
    if (options.throwOnInvalid) {
      throw new Error("Invalid number. Could not parse: " + inputValue + " as " + result);
    }
    numberValue = 0;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(numberValue);
}
